import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import AutocompleteLargeSelect from "./../forms/AutocompleteLargeSelect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export default function DropdownMobileDesktop(props) {
  const isDesk = useMediaQuery("(min-width:960px)");
  const [open, setOpen] = useState(false);
  const isMobile = window.innerWidth <= 960;
  const isDisabled = Boolean(props.disabled || props.items.length <= 0);
  const getInputValue = props.inputValue
    ? props.inputValue
    : !props.disabled && props.items.length <= 0 && props.emptyText
    ? props.emptyText
    : props.label;

  const handleOpen = () => {
    if (!isDisabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  const itemSelected = (selected) => {
    props.onChange(selected);
    handleClose();
  };

  return (
    <div className={props.className}>
      {isDesk && (
        <div>
          <AutocompleteLargeSelect {...props} />
        </div>
      )}

      {!isDesk && (
        <div>
          <div className="p-0 w-100" onClick={handleOpen}>
            <TextField
              label={props.inputValue ? props.label : null}
              value={getInputValue}
              disabled={isDisabled}
              helperText={props.error ? props.errorText : null}
              error={props.error}
              margin="normal"
              fullWidth
              InputProps={{
                readOnly: true,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
                inputProps: {
                  style: { color: isDisabled ? "rgba(0, 0, 0, 0.38)" : undefined },
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <ArrowDropDown />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            className="modal-dropdown"
            style={{ zIndex: 130000000 }}
          >
            <Container className="modal-dropdown-root">
              <header>
                <IconButton edge="start" onClick={handleClose} aria-label="Voltar">
                  <ArrowBack />
                </IconButton>
              </header>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutocompleteLargeSelect {...props} autoFocus={true} onChange={itemSelected} />
                </Grid>
              </Grid>
            </Container>
          </Dialog>
        </div>
      )}
    </div>
  );
}
