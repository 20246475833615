import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect, useRef, createRef } from 'react';
import Select, { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  option: {
    fontSize: 16,
    height: 48,
    fontFamily: 'Gotham Bold',
    backgroundColor: '#ffffff',
    '&.Mui-selected': {
      backgroundColor: '#ffffff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#ffffff',
    },
    color: '#455266',
    '& .label-style': {
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      maxWidth: '190px',
      minWidth: '65px',
      width: 'fit-content',
      '-webkit-box-orient': 'vertical',
      height: 'inherit'
    },
    '& .min-width': {
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      paddingTop: '6px'
    },
    '& .secondary': {
      textTransform: 'uppercase',
      fontSize: 12,
      color: '#99A8BF',
      paddingLeft: 10,
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    ></Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

const Input = (props) => (
  <components.Input {...props} autoComplete="new-password" />
);

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      margin="dense"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props) {
  let secondaryText = null;
  if (
    props.children.secondary &&
    !props.children.label.includes(props.children.secondary)
  ) {
    let campus = props?.children?.campus?.length ? props?.children?.campus?.filter((cmp) => cmp !== 'CENTRAL') : [];
    console.log(campus);
    secondaryText = (
      <div className='min-width'>
        <div className="secondary">
          {props.children.secondary.toLowerCase()}
        </div>
        {campus?.length ? <div className="secondary">{campus.join(', ')}</div> : <></>}{' '}
      </div>
    );
  }
  
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      className={props.selectProps.classes.option}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <span className={secondaryText ? 'label-style' : ''}>{props.children.label}</span>
      {secondaryText}
    </MenuItem>
  );
}

function SingleValue(props) {
  return <Typography {...props.innerProps}>{props.children.label}</Typography>;
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

const IndicatorSeparator = () => {
  return null;
};

function DropdownIndicator() {
  return <ArrowDropDown />;
}

function MenuList(props) {
  const isMobile = window.innerWidth <= 960;
  const height = 48;
  const { options, children, maxHeight, getValue } = props;

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  return (
    <List
      height={isMobile ? maxHeight : 155}
      itemCount={children.length}
      itemSize={height}
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      initialScsollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
}

const customComponents = {
  Control,
  MenuList,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer,
  IndicatorSeparator,
  DropdownIndicator,
  Input,
};

export default function AutocompleteSelect(props) {
  const [helperText, setHelperText] = useState(
    props.autoFocus ? props.helperText : null,
  );
  const [single, setSingle] = useState(null);
  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  useEffect(() => {
    if (!props.inputValue) {
      setSingle(null);
    } else {
      const val = props.items.find((item) => item.label === props.inputValue);

      setSingle(val);
    }
  }, [props.inputValue, props.items]);

  const changedHandler = (value) => {
    setSingle(value);
    props.onChange(value);
  };

  const menuOpenHandler = () => {
    setHelperText(props.helperText);
  };

  const menuCloseHandler = () => {
    setHelperText('');
  };

  const label =
    !props.disabled && props.items.length <= 0 ? props.emptyText : props.label;

  return (
    <div className={classes.root}>
      <NoSsr>
        {/* HIDDEN INPUT PARA INTEGRAÇÃO COM HUBSPOT */}
        <TextField
          label={label}
          value={single ? single.label : ''}
          style={{ display: 'none' }}
        />

        <Select
          classes={classes}
          styles={selectStyles}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option.label}
          TextFieldProps={{
            label,
            helperText: props.error ? props.errorText : helperText,
            autoComplete: 'off',
            InputLabelProps: {
              shrink: single || helperText ? true : false,
            },
          }}
          placeholder=""
          components={customComponents}
          options={props.items}
          isDisabled={
            props.disabled || (!props.onInputChange && props.items.length <= 0)
          }
          noOptionsMessage={() => null}
          autoFocus={props.autoFocus}
          defaultMenuIsOpen={props.autoFocus}
          onMenuOpen={menuOpenHandler}
          onMenuClose={menuCloseHandler}
          onInputChange={props.onInputChange}
          value={single}
          onChange={changedHandler}
        />
      </NoSsr>
    </div>
  );
}
