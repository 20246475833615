import axios from "axios";

const getAllEstados = () => {
  return [
    { label: "Acre", value: "AC" },
    { label: "Alagoas", value: "AL" },
    { label: "Amapá", value: "AP" },
    { label: "Amazonas", value: "AM" },
    { label: "Bahia", value: "BA" },
    { label: "Ceará", value: "CE" },
    { label: "Distrito Federal", value: "DF" },
    { label: "Espírito Santo", value: "ES" },
    { label: "Goiás", value: "GO" },
    { label: "Maranhão", value: "MA" },
    { label: "Mato Grosso", value: "MT" },
    { label: "Mato Grosso do Sul", value: "MS" },
    { label: "Minas Gerais", value: "MG" },
    { label: "Pará", value: "PA" },
    { label: "Paraíba", value: "PB" },
    { label: "Paraná", value: "PR" },
    { label: "Pernambuco", value: "PE" },
    { label: "Piauí", value: "PI" },
    { label: "Rio de Janeiro", value: "RJ" },
    { label: "Rio Grande do Norte", value: "RN" },
    { label: "Rio Grande do Sul", value: "RS" },
    { label: "Rondônia", value: "RO" },
    { label: "Roraima", value: "RR" },
    { label: "Santa Catarina", value: "SC" },
    { label: "São Paulo", value: "SP" },
    { label: "Sergipe", value: "SE" },
    { label: "Tocantins", value: "TO" },
  ];
};

const getEstados = async (niveis) => {
  const estados = getAllEstados();
  if (niveis != null && niveis.length > 0) {
    const response = await axios.get(process.env.REACT_APP_LEGADO_API_BASEURL + "simulador/estados", {
      params: {
        tipo: niveis[0],
        semFiador: "S",
      },
    });
    return estados.filter((estado) => response.data.estados.indexOf(estado.value) !== -1);
  }

  return estados;
};

export const formatInstituicao = (instituicao) => {
  const { nomeAmigavel, solucao, modalidadeBolsa, tipo } = instituicao;
  const isPos = !!tipo.match(/SENSU/i)?.length;
  return {
    label: (nomeAmigavel || "").trim(),
    value: instituicao.id,
    secondary: `${solucao} ${isPos ? `${tipo} - ` : ""}${modalidadeBolsa}`.trim(),
    tipo: instituicao.tipo,
    campus: instituicao.campus,
    estado: instituicao.estado,
    tipoPeriodo: instituicao.tipoContrato,
    codBolsa: instituicao.codigo,
  };
};

const formatCampus = (campus) => {
  return {
    label: campus.nome_campus,
    value: campus.id,
    secondary: campus.cidade,
  };
};
const formatCurso = (curso) => {
  return {
    label: curso.nome,
    value: curso.id,
    tipo: curso.tipo,
    secondary: curso.turno,
    oculto: curso.oculto,
    codBolsa: curso.codBolsa,
    duracaoCredito: curso.duracao,
  };
};

const getInstituicoes = async (estado, niveis) => {
  let params = "maisAcesso=true&";
  if (estado) {
    params = params.concat(`estados=${estado}`);
  }
  if (niveis) {
    params !== "" ? (params = params.concat("&tipos=")) : (params = params.concat("tipos="));
    params = params.concat(niveis.map((item) => item).join("&tipos="));
  }
  const response = await axios.get(process.env.REACT_APP_LEGADO_API_BASEURL + "simulador/bolsas?".concat(params));

  const instituicoes = response.data.data.map((item) => formatInstituicao(item));
  return instituicoes;
};

const getInstituicoesByCodigo = async (codigoList) => {
  const response = await axios.get(process.env.REACT_APP_API_BASEURL + "/pse", {
    params: {
      where: {
        or: codigoList.map((item) => ({ codigo: item })),
      },
    },
  });
  let instituicoes = response.data.data;

  let estados = instituicoes.map((item) => item.estado);
  estados = estados.filter((item, index) => estados.indexOf(item) === index);

  let niveis = instituicoes.map((item) => item.tipo);
  niveis = niveis.filter((item, index) => niveis.indexOf(item) === index);

  instituicoes = instituicoes.map((item) => formatInstituicao(item));
  return { instituicoes, estados, niveis };
};

const getCampus = async (instituicaoId, estado) => {
  let whereParams;
  if (estado) whereParams = { or: [{ estado: { contains: estado } }], pse: instituicaoId };
  else whereParams = { pse: instituicaoId };

  const response = await axios.get(process.env.REACT_APP_API_BASEURL + "/campus", {
    params: {
      limit: 1000,
      where: whereParams,
    },
  });
  let allCampus = response.data.data;
  allCampus = Array.isArray(allCampus) ? allCampus.map((item) => formatCampus(item)) : [];
  return allCampus;
};

const getCursos = async (instituicaoId, niveis, codCampus = null) => {
  const where = {
    pse: instituicaoId,
    or: niveis.map((item) => ({ tipo: { contains: item } })),
  };
  if (codCampus) {
    where.campus = codCampus;
  }

  const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/curso`, {
    params: {
      limit: 1000,
      where: where,
    },
  });
  const cursos = response.data.data.map((item) => formatCurso(item));
  return cursos;
};

const getCobertura = async (instituicaoId, cursoId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_LEGADO_API_BASEURL}bolsas/` + instituicaoId + `/curso/` + cursoId + `/cobertura`
  );
  if (response.data.tipo === "O") {
    response.data.valores.sort(function (a, b) {
      if (a.valor > b.valor) {
        return 1;
      }
      if (a.valor < b.valor) {
        return -1;
      }
      return 0;
    });
  }
  return response;
};

const getLocation = (obj) => {
  return axios.get(
    "https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=" +
      obj.coords.latitude +
      "&lon=" +
      obj.coords.longitude
  );
};

const montarResultadoSimulador = async (paramSimulacao) => {
  //Calculo de duracao
  let dataAtual = new Date(),
    mesAtual = dataAtual.getMonth() > 5 ? 0 : 6,
    anoAtual = dataAtual.getMonth() > 5 ? dataAtual.getFullYear() + 1 : dataAtual.getFullYear(),
    arrMeses = {},
    arrAnos = {},
    nMeses = paramSimulacao.tipoPeriodo === tiposPeriodo.quadrimestral ? 4 : 6;

  for (let i = 1; i <= paramSimulacao.duracaoDoCredito * nMeses; i++) {
    if (mesAtual > 11) {
      mesAtual = 1;
      anoAtual++;
      arrMeses["month_" + i] = mesAtual;
      arrAnos["year_" + i] = anoAtual;
    } else {
      mesAtual++;
      arrMeses["month_" + i] = mesAtual;
      arrAnos["year_" + i] = anoAtual;
    }
  }

  //Data conclusao
  let dataDaConclusao = new Date(anoAtual, mesAtual - 1, 1);
  dataDaConclusao = [
    dataDaConclusao.getDate() < 10 ? "0" + dataDaConclusao.getDate() : dataDaConclusao.getDate(),
    dataDaConclusao.getMonth() + 1 < 10 ? "0" + (dataDaConclusao.getMonth() + 1) : dataDaConclusao.getMonth() + 1,
    dataDaConclusao.getFullYear(),
  ];

  const respSimulacao = await axios.get(`${process.env.REACT_APP_API_BASEURL}/simulacoesweb`, {
    params: {
      produto: paramSimulacao.produto,
      curso: paramSimulacao.curso,
      matriculado: "nao",
      dataDaConclusao: dataDaConclusao.join(""),
      duracaoDoCredito: paramSimulacao.duracaoDoCredito,
      valorDaMensalidade: paramSimulacao.mensalidade_curso,
      percentualSolicitado: paramSimulacao.percentualSolicitado,
      email: paramSimulacao.email,
      newsletter: paramSimulacao.newsletter,
      tipoPeriodo: paramSimulacao.tipoPeriodo,
      // origem: getParameterByName('utm_source'),
      // midia: getParameterByName('utm_medium'),
      // campanha: getParameterByName('utm_campaign'),
      // palavra_chave: getParameterByName('utm_term'),
      // conteudo: getParameterByName('utm_content')
    },
  });
  return respSimulacao.data.data;
};

const isPos = (niveis) => {
  for (var i = 0; i < niveis.length; i++) {
    if (niveis[i].includes("LATO") || niveis[i].includes("STRICTO") || niveis[i].includes("PÓS")) {
      return true;
    }
  }
  return false;
};

const tiposPeriodo = {
  semestral: "S",
  anual: "A",
  quadrimestral: "Q",
  unico: "U",
};

const textoTipoPeriodo = {
  S: "semestre",
  A: "ano",
  Q: "quadrimestre",
  U: "unico",
};

export {
  getAllEstados,
  getEstados,
  getInstituicoes,
  getInstituicoesByCodigo,
  getCampus,
  getCursos,
  getCobertura,
  getLocation,
  montarResultadoSimulador,
  isPos,
  tiposPeriodo,
  textoTipoPeriodo,
};
